import { Stripe, StripeElements } from '@stripe/stripe-js'
import { navigate } from 'gatsby'
import { IconButton, Pencil } from 'nzk-react-components'
import React, { MutableRefObject } from 'react'
import styled from 'styled-components'
import { FieldGroup, FormField } from './index.styles'
import useSubscribePageState from './useSubscribePageState'

const Wrapper = styled.div`
  width: 270px;
  min-width: 270px;
  background-color: #341644;
  padding: 10px 20px;
  box-shadow: 0 -3px 0 #531d75, 0 4px 0 #1c042b, 0 8px 0 rgba(0, 0, 0, 0.4);
  border-radius: 5px;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
`

export interface ICardDetails {
  elements: StripeElements | null
  stripe: Stripe | null
  cardholderNameRef: MutableRefObject<HTMLInputElement | null>
}

const CardDetails = () => {
  const { userData } = useSubscribePageState()

  if (!userData?.subscription?.customer?.card) return null
  return (
    <Wrapper>
      <FormField>
        <div className="label">Cardholder Name</div>
        <div>{userData?.name}</div>
      </FormField>
      <FormField>
        <div className="label">Card Number</div>
        <div>**** **** **** {userData?.subscription.customer.card.last4}</div>
      </FormField>
      <FieldGroup>
        <FormField>
          <div className="label">Expiry</div>
          <div>
            {userData?.subscription.customer.card.exp_month}/
            {userData?.subscription.customer.card.exp_year}
          </div>
        </FormField>
        <FormField style={{ maxWidth: '60px' }}>
          <div className="label">CVC</div>
          <div>***</div>
        </FormField>
      </FieldGroup>
      <Actions>
        <IconButton
          theme="yellow"
          icon={<Pencil />}
          size="x-small"
          onClick={() => {
            navigate('/account/update-card-details')
          }}
        >
          Edit
        </IconButton>
      </Actions>
    </Wrapper>
  )
}

CardDetails.defaultProps = {
  cardDetailsRef: null,
}

export default CardDetails
