import { useApolloClient } from '@apollo/client'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { useAsync } from 'nzk-react-components'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useCurrentUserState } from 'state/CurrentUserState'
import { createContainer } from 'unstated-next'
import { ICardDetails } from './CardDetails'
import FIND_DISCOUNT_CODE from './graphql/findDiscountCode.graphql'
import useData, { IPlan, IPlanTier } from './useData'
import { getCurrencyFromCountry } from './utils'

interface ChildToCreate {
  nickname: string
  birthMonth: number
  birthYear: number
}

const SubscribePageState = () => {
  const client = useApolloClient()
  const location = useLocation()
  const { currentUser } = useCurrentUserState()
  const [selectedPlan, selectPlan] = useState<IPlan | null>(null)
  const [_tier, setTier] = useState<IPlanTier | null>(null)
  const [childrenToCreate, setChildrenToCreate] = useState<ChildToCreate[]>([])
  const [country, setCountry] = useState(currentUser?.countryCode || 'GB')
  const [discount, setDiscount] = useState<number | null>(null)
  const [coupon, setCoupon] = useState<string | null>(null)
  const [updating, setUpdating] = useState<boolean>(false)
  const [createCard, setCreateCard] = useState(false)
  const [forcedNewCard, setForcedNewCard] = useState(false)
  const cardDetailsRef = useRef<ICardDetails | null>(null)

  const currency = useMemo(() => {
    return getCurrencyFromCountry(country)
  }, [country])

  const { findPlan, getUserData, createSubscription } = useData()

  const { loading: loadingUserData, data: userData } = useAsync({
    asyncFunc: getUserData,
    immediate: true,
  })

  useEffect(() => {
    if (
      userData?.subscription?.plan?.currency &&
      userData?.subscription?.plan?.currency !== currency
    ) {
      setForcedNewCard(true)
      setCreateCard(true)
    } else {
      setCreateCard(false)
      setForcedNewCard(false)
    }
  }, [userData, currency])

  const hasExistingCard = useMemo(() => {
    return Boolean(userData?.subscription?.customer?.card?.last4)
  }, [userData])

  const shouldCollectCardDetails = useMemo(() => {
    return createCard || !hasExistingCard
  }, [createCard, hasExistingCard])

  const {
    loading: loadingPlan,
    data: availablePlans,
    execute: fetchPlans,
  } = useAsync({
    asyncFunc: async (params = { currency: 'gbp' }) => {
      const monthly = await findPlan(params.currency, 'monthly')
      const quarterly = await findPlan(params.currency, 'quarterly')
      const yearly = await findPlan(params.currency, 'yearly')
      const biyearly = await findPlan(params.currency, 'biyearly')

      const p: {
        monthly?: IPlan
        quarterly?: IPlan
        yearly?: IPlan
        biyearly?: IPlan
      } = {}
      if (monthly) p.monthly = monthly
      if (quarterly) p.quarterly = quarterly
      if (yearly) p.yearly = yearly
      if (biyearly) p.biyearly = biyearly
      return p
    },
    immediate: false,
  })

  useEffect(() => {
    fetchPlans({ currency })
  }, [currency])

  const plansMap = useMemo(() => {
    if (!availablePlans) return {}
    return Object.keys(availablePlans).reduce((acc, key) => {
      const newAcc = { ...acc }
      newAcc[availablePlans[key].id] = availablePlans[key]
      return newAcc
    }, {}) as { [key: string]: IPlan }
  }, [availablePlans])

  const nbChildren = useMemo(() => {
    return (currentUser?.children || []).length + childrenToCreate.length
  }, [currentUser, childrenToCreate])

  const maxChildren = useMemo(() => {
    if (!selectedPlan || !selectedPlan.tiers) return 0
    return Math.max(...selectedPlan.tiers.map((t) => t.upTo))
  }, [selectedPlan])

  const canAddChild = useMemo(() => {
    return nbChildren < maxChildren
  }, [nbChildren, maxChildren])

  const tier = useMemo(() => {
    if (!_tier) return null
    const flatAmount = _tier.flatAmount || 0
    const unitAmount = _tier.unitAmount || 0
    const amount = flatAmount + unitAmount * nbChildren
    return {
      ..._tier,
      amount,
    }
  }, [_tier, nbChildren])

  const tierHasUnitPrice = useMemo(() => {
    if (!_tier) return false
    return _tier.unitAmount > 0
  }, [_tier])

  const addChildToCreate = (child: ChildToCreate) => {
    if (!canAddChild) return
    setChildrenToCreate((c) => [...c, child])
  }

  const removeChildToCreateAtIndex = (index: number) => {
    setChildrenToCreate((c) => {
      const newC = [...c]
      newC.splice(index, 1)
      return newC
    })
  }

  const availableTiers = useMemo(() => {
    if (!selectedPlan) return []
    return (selectedPlan.tiers || []).filter(
      (tier) =>
        (tier.upTo === null && tier.unitAmount > 0) || tier.upTo >= nbChildren
    )
  }, [selectedPlan, nbChildren])

  const tiersMap = useMemo(() => {
    if (!availableTiers) return {}

    return availableTiers.reduce((acc, tier) => {
      const newAcc = { ...acc }
      newAcc[tier.id] = tier
      return newAcc
    }, {})
  }, [availableTiers])

  const selectPlanById = (id) => {
    selectPlan(plansMap[id])
  }

  const selectTierById = (id) => {
    setTier(tiersMap[id])
  }

  useEffect(() => {
    if (availablePlans) {
      if (userData?.subscription?.plan?.interval === 'month') {
        if (userData?.subscription?.plan?.interval_count === 3) {
          selectPlan(availablePlans.quarterly)
        } else {
          selectPlan(availablePlans.monthly)
        }
      } else if (userData?.subscription?.plan?.interval === 'year') {
        selectPlan(availablePlans.yearly)
      } else if (availablePlans.monthly) {
        selectPlan(availablePlans.monthly)
      } else if (availablePlans.quarterly) {
        selectPlan(availablePlans.quarterly)
      }
    }
  }, [userData, availablePlans])

  useEffect(() => {
    if (availableTiers) setTier(availableTiers[0])
  }, [availableTiers])

  const applyCoupon = async (
    couponId
  ): Promise<{ error: string | null; discount: number }> => {
    try {
      const { data } = await client.query({
        query: FIND_DISCOUNT_CODE,
        variables: {
          couponId,
        },
      })
      if (data.findDiscountCode) {
        setDiscount(data.findDiscountCode / 100)
        setCoupon(couponId)
        return { error: null, discount: data.findDiscountCode / 100 }
      }
      throw new Error('No such coupon.')
    } catch (err) {
      return { error: 'No such coupon.', discount: 0 }
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const coupon = params.get('coupon')
    if (coupon) {
      applyCoupon(coupon)
    }
  }, [])

  const canChangeCountry = useMemo(() => {
    if (!userData) return null
    return (
      !userData?.subscription ||
      getCurrencyFromCountry(userData?.countryCode) !==
        userData?.subscription?.plan?.currency
    )
  }, [userData])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const countryCode = params.get('country')
    if (canChangeCountry !== false && countryCode) {
      setCountry(countryCode)
    } else if (currentUser && !canChangeCountry) {
      setCountry(currentUser.countryCode)
    }
  }, [canChangeCountry])

  const canChangePeriod = useMemo(() => {
    if (!userData) return null
    return (
      !userData?.subscription?.id ||
      userData?.subscription?.status === 'cancelled' ||
      (userData?.customPlan && userData?.subscription?.status === 'trialing')
    )
  }, [userData])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const period = params.get('period')
    if (canChangePeriod && period === 'monthly' && availablePlans?.monthly)
      selectPlan(availablePlans.monthly)
    else if (
      canChangePeriod &&
      period === 'quarterly' &&
      availablePlans?.quarterly
    )
      selectPlan(availablePlans.quarterly)
    else if (canChangePeriod && period === 'yearly' && availablePlans?.yearly)
      selectPlan(availablePlans.yearly)
  }, [availablePlans, canChangePeriod])

  const canSubmit = useMemo(() => {
    return nbChildren <= maxChildren
  }, [nbChildren, maxChildren])

  const submit = async () => {
    if (updating) return null
    setUpdating(true)

    if (!tier || !selectedPlan) throw new Error('No plan / tier selected.')

    if (tier.upTo !== null && tier.upTo < nbChildren)
      throw new Error('Too many children for selected plan / tier.')
    if (tier.upTo === null && !tier.unitAmount)
      throw new Error('Too many children for selected plan / tier.')

    const subscription = await createSubscription({
      price: selectedPlan.id,
      quantity: nbChildren,
      newChildren: childrenToCreate,
      coupon: coupon || undefined,
    })
    if (!subscription) {
      setUpdating(false)
      return
    }
    if (subscription.status === 'ERROR') {
      setUpdating(false)
      throw new Error(subscription.error)
    }
    if (subscription.status === 'SUCCESS') {
      navigate('/account?forceRefetch=true')
    } else if (subscription.paymentIntent?.clientSecret) {
      navigate(
        `/subscribe/confirm?pi_client_secret=${subscription.paymentIntent.clientSecret}`
      )
    } else if (subscription.setupIntent?.clientSecret) {
      navigate(
        `/subscribe/confirm?si_client_secret=${subscription.setupIntent.clientSecret}`
      )
    }
    setUpdating(false)
  }

  return {
    loading: loadingPlan || loadingUserData,
    updating,
    userData,
    nbChildren,
    canChangeCountry,
    canChangePeriod,
    availablePlans,
    selectedPlan,
    selectPlan,
    availableTiers,
    selectPlanById,
    tier,
    tierHasUnitPrice,
    selectTierById,
    childrenToCreate,
    addChildToCreate,
    removeChildToCreateAtIndex,
    canAddChild,
    country,
    setCountry,
    cardDetailsRef,
    canSubmit,
    submit,
    discount,
    applyCoupon,
    shouldCollectCardDetails,
    hasExistingCard,
    forcedNewCard,
    setCreateCard,
  }
}

const SubscribePageStateContainer = createContainer(SubscribePageState)

const useSubscribePageState = SubscribePageStateContainer.useContainer

export const SubscriptionPageStateProvider =
  SubscribePageStateContainer.Provider

export default useSubscribePageState

