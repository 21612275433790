import { useApolloClient } from '@apollo/client'
import CREATE_SUBSCRIPTION from './graphql/createSubscriptionV2.graphql'
import CREATE_TUTORING_STUDENT from './graphql/createTutoringStudent.graphql'
import FIND_PLAN from './graphql/findPlan.graphql'
import GET_DATA from './graphql/getData.graphql'
import GET_PLAN from './graphql/getPlan.graphql'

export interface IPlan {
  id: string
  title: string
  currency: string
  period: 'monthly' | 'yearly' | 'quarterly' | 'biyearly'
  tiers: IPlanTier[]
}

export interface UserData {
  _id: string
  name: string
  countryCode: string
  children: {
    _id: string
    username: string
    nickname: string
    avatar: {
      url: string
    }
    type: string
  }[]
  subscription: {
    id: string
    status: string
    customer: {
      id: string
      card: {
        id: string
        status: string
        last4: string
      }
    }
  }
}

export interface IPlanTier {
  id: number
  amount: number
  upTo: number
  flatAmount: number
  unitAmount: number
}

export interface CreateSubscriptionInput {
  price: string
  quantity: number
  newChildren?: CreateTutoringStudentInput[]
  coupon?: string
}

export interface CreateTutoringStudentInput {
  birthMonth: number
  birthYear: number
  nickname: string
}

export interface CreateTutoringStudentReturn {
  _id: string
  username: string
  avatar: {
    url: string
  }
  nickname: string
  birthYear: number
  birthMonth: number
}

export interface ISubscription {
  id: string
  status: string
  plan: {
    id: string
    currency: string
  }
  customer: {
    id: string
    card: {
      id: string
      last4: string
      exp_month: string
      exp_year: string
    }
  }
}

const useData = () => {
  const client = useApolloClient()

  const getUserData = async (): Promise<UserData> => {
    const { data } = await client.query({
      query: GET_DATA,
    })
    return data.me
  }

  const findPlan = async (
    currency: string,
    term: 'quarterly' | 'yearly' | 'monthly' | 'biyearly'
  ): Promise<IPlan | null> => {
    const { data, error } = await client.query({
      query: FIND_PLAN,
      variables: {
        currency,
        term,
      },
      fetchPolicy: 'network-only',
    })

    const TITLES = {
      quarterly: 'Quarterly',
      monthly: 'Monthly',
      yearly: 'Yearly',
      biyearly: '6 Month',
    }

    if (error) return null
    if (!data.findPlan) return null
    let plan = { ...data.findPlan, period: term }
    if (plan.tiers?.length > 0) {
      plan = {
        ...plan,
        title: TITLES[term],
        tiers: plan.tiers.reduce((acc, tier, i) => {
          let currTier = { ...tier, id: tier.upTo }
          if (currTier.upTo === null) {
            currTier = {
              ...currTier,
              upTo: null,
              id: Math.max(...plan.tiers.map((t) => t.upTo || 0)) + 1,
            }
          }
          if (
            plan.tiers[i - 1] &&
            plan.tiers[i - 1].upTo !== currTier.upTo - 1
          ) {
            return [
              ...acc,
              { ...currTier, id: currTier.upTo - 1, upTo: currTier.upTo - 1 },
              currTier,
            ]
          }
          return [...acc, currTier]
        }, []),
      }
    }
    return plan
  }

  const getPlan = async (id: string) => {
    const { data } = await client.query({
      query: GET_PLAN,
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    })

    let plan = { ...data.plan }
    if (plan.tiers?.length > 0) {
      plan = {
        ...plan,
        tiers: plan.tiers.reduce((acc, tier, i) => {
          let currTier = { ...tier }
          if (currTier.upTo === null) {
            currTier = {
              ...currTier,
              upTo: Math.max(...plan.tiers.map((t) => t.upTo || 0)) + 1,
            }
          }
          if (
            plan.tiers[i - 1] &&
            plan.tiers[i - 1].upTo !== currTier.upTo - 1
          ) {
            return [...acc, { ...currTier, upTo: currTier.upTo - 1 }, currTier]
          }
          return [...acc, currTier]
        }, []),
      }
    }
    return plan
  }

  const createSubscription = async (
    input: CreateSubscriptionInput
  ): Promise<{
    status: 'SUCCESS' | 'REQUIRE_ACTION' | 'ERROR'
    error?: string
    setupIntent?: {
      clientSecret: string
    }
    paymentIntent?: {
      clientSecret: string
    }
    invoice: {
      id: string
      amount_due: number
    }
  } | null> => {
    const { data } = await client.mutate({
      mutation: CREATE_SUBSCRIPTION,
      variables: {
        input: {
          priceId: input.price,
          quantity: input.quantity,
          newChildren: input.newChildren,
          coupon: input.coupon,
        },
      },
    })
    return data.customer_createSubscription
  }

  const createChild = async (
    input: CreateTutoringStudentInput
  ): Promise<CreateTutoringStudentReturn> => {
    const { data } = await client.mutate({
      mutation: CREATE_TUTORING_STUDENT,
      variables: {
        input: {
          birthMonth: input.birthMonth,
          birthYear: input.birthYear,
          nickname: input.nickname,
        },
      },
    })
    return data.createTutoringStudent
  }

  const createChildren = async (
    input: CreateTutoringStudentInput[]
  ): Promise<CreateTutoringStudentReturn[]> => {
    const newChildren: CreateTutoringStudentReturn[] = []
    await input.reduce(async (acc, child) => {
      await acc
      const newChild = await createChild(child)
      newChildren.push(newChild)
      return Promise.resolve()
    }, Promise.resolve())
    return newChildren
  }

  return { getUserData, findPlan, getPlan, createSubscription, createChildren }
}

export default useData
