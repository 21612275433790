import React from 'react'
import ReactSelect from 'react-select'

interface IProps {
  options: { label: string, value: string | number }[]
  value: string | number,
  onChange: (string) => void,
  placeholder?: string,
  disabled?: boolean,
  searchable?: boolean,
  clearable?: boolean
}

const Select = (props: IProps) => {
  const onChange = (option) => {
    props.onChange(option.value)
  }

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 100
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected || state.isFocused ? '#fff' : '#671C9C',
      backgroundColor: state.isSelected ? '#671C9C' : (state.isFocused ? '#8423CAaa' : '#fff'),
      padding: 10,
      zIndex: 100
    }),
    control: () => ({
      borderColor: '#f00',
      display: 'flex',
      borderRadius: '8px',
      backgroundColor: 'white',
      boxShadow: 'rgb(0 0 0 / 10%) 0px 3px 2px',
      width: '100%',
      outline: 'none',
      border: '1.5px solid #8423CA',
    }),
  }

  return <ReactSelect
    onChange={(l) => onChange(l)}
    value={props.options.filter(o => o.value === props.value)}
    styles={customStyles}
    isSearchable={props.searchable}
    isClearable={props.clearable}
    isDisabled={props.disabled}
    placeholder={props.placeholder}
    options={props.options}
  />
}  

Select.defaultProps = {
  placeholder: undefined,
  disabled: false,
  searchable: true,
  clearable: false
}

export default Select
