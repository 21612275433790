import { Button, Close } from 'nzk-react-components'
import React, { useState } from 'react'
import styled from 'styled-components'
import DropDown from '../UI/DropDown'
import Input from '../UI/Input'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background-color: rgba(0,0,0,0.6);
`

const Wrapper = styled.div`
  position: relative;
  background-color: #341644;
  color: #fff;
  max-width: 320px;
  border-radius: 19px;
  box-shadow: 0 -3px 0 #531d75, 0 4px 0 #1c042b, 0 8px 0 rgba(0, 0, 0, 0.4);
  > :first-child {
    position: absolute;
    top: -5px;
    right: -5px;
  }
`

const Header = styled.div`
  font-size: 23px;
  padding: 20px 12px 10px 12px;
  font-family: 'Rammetto One';
  text-align: center;
`

const Content = styled.div`
  > :nth-child(2n + 1) {
    background-color: #3f224e;
  }
  > :nth-child(2n + 2) {
    background-color: #432751;
  }
`

const Actions = styled.div`
  padding: 20px 12px 30px 12px;
  display: flex;
  justify-content: center;
`

const Section = styled.div`
  width: 100%;
  padding: 6px 12px 10px 12px;
  > :first-child {
    margin-bottom: 6px;
    font-size: 12px;
    font-family: 'Rammetto One';
  }
  > :nth-child(2) {
    font-size: 12px;
    font-family: 'Libre Baskerville';
  }
  p {
    word-break: break-word;
    white-space: initial;
  }
`

const BirthDate = styled.div`
  display: flex;
  > * {
    font-family: 'Rammetto One';
  }
  > :first-child {
    margin-right: 10px;
  }
`

const MONTHS = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
]

const YEARS = new Array(9).fill(undefined).map((v, i) => {
  const min = new Date().getUTCFullYear() - 13
  return { value: min + i, label: `${min + i}` }
})

interface IProps {
  onSubmit: (data: {
    nickname: string
    birthMonth: number
    birthYear: number
  }) => Promise<void> | void
  dismiss: () => void
}

const AddChildModal = (props: IProps) => {
  const [loading, setLoading] = useState(false)
  const [nickname, setNickname] = useState('')
  const [formData, setFormData] = useState({
    birthMonth: 0,
    birthYear: 2014,
  })

  const onSubmit = async () => {
    if (loading) return
    setLoading(true)
    await props.onSubmit({ ...formData, nickname })
    setLoading(false)
    props.dismiss()
  }

  return (
    <Overlay>
      <Wrapper>
        <Button
          size="x-small"
          backgroundColor="#f00"
          onClick={props.dismiss}
          round
        >
          <Close />
        </Button>
        <Header>
          <div>Add Child</div>
        </Header>
        <Content>
          <Section>
            <div>Nickname</div>
            <div>
              <Input
                placeholder="Nickname"
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
              />
            </div>
          </Section>
          <Section>
            <div>Birth Month & Year</div>
            <BirthDate>
              <DropDown
                onChange={(value) =>
                  setFormData((f) => ({ ...f, birthMonth: parseInt(value, 10) }))
                }
                options={MONTHS}
                value={formData.birthMonth}
                width={194}
                height={45}
              />
              <DropDown
                onChange={(value) =>
                  setFormData((f) => ({ ...f, birthYear: parseInt(value, 10) }))
                }
                options={YEARS}
                value={formData.birthYear}
                width={90}
                height={45}
              />
            </BirthDate>
          </Section>
          <Section>
            <div>Level of Difficulty</div>
            <div>
              <p>
                You can adjust level of difficulty in the child's settings area
                in your dashboard at any time.
              </p>
            </div>
          </Section>
        </Content>
        <Actions>
          <Button theme="primary" size="small" onClick={onSubmit} disabled={loading}>
            { loading ? 'Loading...' : 'Confirm' }
          </Button>
        </Actions>
      </Wrapper>
    </Overlay>
  )
}

export default AddChildModal
