import React from "react"
import SubscribePage from '../components/pages/SubscribePage'
import Layout from '../layouts/layout'

const Subscribe = props => {
  if (typeof window === "undefined") return null
  return <Layout>
    <SubscribePage {...props} />
  </Layout>
}

export default Subscribe
