import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  background-color: #341644;
  padding: 10px 20px;
  box-shadow: 0 -3px 0 #531d75, 0 4px 0 #1c042b, 0 8px 0 rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 270px;
  min-width: 370px;
  font-size: 20px;
  line-height: 28px;
  a {
    color: #fff;
  }
  > p {
    width: 100%;
  }
  @media (max-width: 1073px) {
    margin-top: 15px;
  }
`

const QuoteOrDistrict = () => {
  return <Wrapper>
    <p>
      Would you like a quote for your school or district? Email <a href="mailto:teachers@nightzookeeper.com">teachers@nightzookeeper.com</a> for a personalized quote. 
    </p>
  </Wrapper>
}

export default QuoteOrDistrict
