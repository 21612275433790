// @ts-ignore
import CountrySelector from 'components/UI/CountrySelector'
import Loader from 'components/UI/Loader'
import Select from 'components/UI/Select'
import { Button } from 'nzk-react-components'
import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../../theme'
import 'twin.macro'
import CardDetails from './CardDetails'
import ChildrenRow from './ChildrenRow'
import { FormField } from './index.styles'
import QuoteOrDistrict from './QuoteOrDistrict'
import SubscriptionDetails from './SubscriptionDetails'
import useSubscribePageState, {
  SubscriptionPageStateProvider,
} from './useSubscribePageState'
import { ENABLED_COUNTRIES } from './utils'

const Wrapper = styled.div`
  color: #fff;
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px 10px;
`

const Title = styled.div`
  font-size: 22px;
  line-height: 26px;
  text-shadow: 0px 3px #afafaf, 0px 6px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-family: 'Rammetto One', cursive;
  margin-bottom: 0px;
  text-align: center;
  margin-bottom: 15px;
`

const CheckoutRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  > * {
    margin-right: 15px;
  }
  > :last-child {
    margin-right: 0;
  }

  @media (max-width: 580px) {
    > * {
      width: 100%;
    }
    > :first-child {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`

const Section = styled.div`
  max-width: 530px;
  margin: 0 auto;
  > .section-title {
    font-size: 21px;
    text-shadow: 0px 3px #afafaf, 0px 6px rgba(0, 0, 0, 0.3);
    font-family: 'Rammetto One', cursive;
    text-align: center;
    margin-bottom: 12px;
  }
`

const CountrySelectorWrapper = styled.div`
  max-width: 290px;
`

const Form = styled.div`
  //
`

const ErrorMessage = styled.div`
  text-align: center;
  max-width: 400px;
  background-color: ${colors.red};
  padding: 10px;
  border-radius: 8px;
  margin: 25px auto 10px auto;
  color: ${colors.white};
  a {
    text-decoration: underline;
  }
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const SubscribePage = () => {
  const [error, setError] = useState(null)
  const {
    userData,
    loading,
    updating,
    canChangeCountry,
    nbChildren,
    canChangePeriod,
    availablePlans,
    selectedPlan,
    selectPlanById,
    availableTiers,
    tier,
    tierHasUnitPrice,
    selectTierById,
    country,
    setCountry,
    submit,
  } = useSubscribePageState()
  const [showChildren, setShowChildren] = useState(nbChildren <= 10)

  const onSubmit = async () => {
    if (!selectedPlan || !tier || updating) return null
    try {
      await submit()
    } catch (err: any) {
      setError(err.message)
    }
  }

  if (loading)
    return (
      <Wrapper>
        <Title>Subscription</Title>
        <Loader />
      </Wrapper>
    )

  if (!availablePlans || availablePlans.length === 0) {
    return (
      <Wrapper>
        <Title>No available plans</Title>
        <p tw="text-center">
          Please get in touch by sending an email to{' '}
          <a href="mailto:support@nightzookeeper.com">
            support@nightzookeeper.com
          </a>
        </p>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Section>
        <Title>Subscription</Title>
        <Form>
          <FormField>
            <div className="label">Country</div>
            <CountrySelectorWrapper>
              <CountrySelector
                disabled={!canChangeCountry}
                value={country}
                onChange={(d) => setCountry(d)}
                countryCodes={ENABLED_COUNTRIES}
              />
            </CountrySelectorWrapper>
          </FormField>
          <FormField>
            <div className="label">Period</div>
            <Select
              disabled={!canChangePeriod}
              options={Object.keys(availablePlans).map((key) => ({
                value: availablePlans[key].id,
                label: availablePlans[key].title,
              }))}
              onChange={(id) => selectPlanById(id)}
              value={selectedPlan?.id || ''}
            />
          </FormField>
          <FormField>
            <div className="label">
              <div>
                Children ({nbChildren}/{tier?.upTo || 0 > 20 ? '∞' : tier?.upTo}
                )
              </div>
              <div>
                {showChildren ? (
                  <Button
                    theme="primary"
                    size="x-small"
                    onClick={() => setShowChildren(false)}
                  >
                    Hide
                  </Button>
                ) : (
                  <Button
                    theme="primary"
                    size="x-small"
                    onClick={() => setShowChildren(true)}
                  >
                    Show
                  </Button>
                )}
              </div>
            </div>
            {!tierHasUnitPrice && (
              <Select
                options={availableTiers.map((tier) => {
                  if (tier.upTo === null) {
                    return {
                      value: tier.id,
                      label: 'Any number of children',
                    }
                  }
                  return {
                    value: tier.id,
                    label: `${tier.upTo} ${
                      tier.upTo > 1 ? 'children' : 'child'
                    }`,
                  }
                })}
                value={tier?.id || ''}
                onChange={(id) => selectTierById(id)}
              />
            )}
            {showChildren && <ChildrenRow />}
          </FormField>
        </Form>
      </Section>
      <Section>
        <Title>Payment</Title>
        {selectedPlan && tier && (
          <CheckoutRow>
            <SubscriptionDetails />
            <CardDetails />
            {userData.userType === 'teacher' && <QuoteOrDistrict />}
          </CheckoutRow>
        )}
      </Section>
      {error && (
        <ErrorMessage>
          <div dangerouslySetInnerHTML={{ __html: error }} />
        </ErrorMessage>
      )}
      {(!selectedPlan || !tier) && (
        <ErrorMessage>Please select a plan above</ErrorMessage>
      )}
      {selectedPlan && tier && (
        <Actions>
          <Button
            theme="primary"
            size="regular"
            onClick={onSubmit}
            disabled={!selectedPlan || !tier || updating}
          >
            {updating ? 'Loading...' : 'Submit'}
          </Button>
        </Actions>
      )}
    </Wrapper>
  )
}

export default () => {
  return (
    <SubscriptionPageStateProvider>
      <SubscribePage />
    </SubscriptionPageStateProvider>
  )
}
