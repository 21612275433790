import { Button } from 'nzk-react-components';
import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { FormField, Input } from './index.styles';
import useSubscribePageState from './useSubscribePageState';
import { getFormattedAmount } from './utils';

const Wrapper = styled.div`
  position: relative;
  background-color: #341644;
  padding: 10px 20px;
  box-shadow: 0 -3px 0 #531d75, 0 4px 0 #1c042b, 0 8px 0 rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > .title {
    font-family: 'Rammetto One';
  }
  > .amount {
    font-size: 28px;
    margin: 10px 0 3px 0;
  }
  > .frequency {
    font-size: 14px;
  }
  > .up-to {
    font-size: 14px;
  }
  > .billed {
    font-size: 14px;
    max-width: 200px;
    margin: 0 auto;
  }
  s {
    margin-right: 8px;
  }
`

const CouponWrapper = styled(FormField)`
  margin-top: 20px;
  > * { display: flex; justify-content: center; align-items: center; }
  input { margin-right: 10px; width: 120px; }
  .label {
    text-decoration: underline;
    cursor: pointer;
  }
  .error {
    font-size: 13px;
    font-weight: bold;
    color: #f00;
  }
  .discount {
    font-size: 13px;
    font-weight: bold;
    color: #8CC63F;
  }
`

const SubscriptionDetails = () => {
  const { selectedPlan, tier, discount, applyCoupon, tierHasUnitPrice } = useSubscribePageState()
  const [enteringCoupon, setEnteringCoupon] = useState(false)
  const couponRef = useRef<HTMLInputElement | null>(null)
  const [couponError, setCouponError] = useState<string | null>(null)

  const TITLES = { 'monthly': 'Monthly', 'quarterly': 'Quarterly', 'yearly': 'Yearly', 'biyearly': '6 Months' }

  const unitAmount = useMemo(() => {
    if (!selectedPlan || !tier) return null
    let amount = tier.unitAmount 
    if (selectedPlan.period === 'yearly') amount /= 12
    if (selectedPlan.period === 'quarterly') amount /= 3
    if (selectedPlan.period === 'biyearly') amount /= 6
    return Intl.NumberFormat('en-us', { style: 'currency', currency: selectedPlan.currency }).format(getFormattedAmount(amount, selectedPlan.currency))
  }, [selectedPlan, tier])

  const frequency = useMemo(() => {
    if (selectedPlan?.period === 'monthly')
    return `per month.`
    if (selectedPlan?.period === 'quarterly')
    return `every 3 months.`
    if (selectedPlan?.period === 'biyearly')
    return `every 6 months.`
    if (selectedPlan?.period === 'yearly')
    return `per year.`
  }, [selectedPlan])

  const discountedAmount = useMemo(() => {
    if (!selectedPlan || !tier || !discount) return null;
    return Intl.NumberFormat('en-us', { style: 'currency', currency: selectedPlan.currency }).format(getFormattedAmount(tier.amount * (1 - discount), selectedPlan.currency))
  }, [selectedPlan, tier, discount])

  const amount = useMemo(() => {
    if (!selectedPlan || !tier) return null;
    return Intl.NumberFormat('en-us', { style: 'currency', currency: selectedPlan.currency }).format(getFormattedAmount(tier.amount, selectedPlan.currency))
  }, [selectedPlan, tier])

  const onApplyCoupon = async () => {
    if (!couponRef.current?.value) return
    setCouponError(null)
    const { error } = await applyCoupon(couponRef.current.value)
    if (error) {
      setCouponError(error)
    } else {
      couponRef.current.value = ''
    }
    setEnteringCoupon(false)
  }

  if (!selectedPlan || !tier) return null
  // const amount = Intl.NumberFormat('en-us', { style: 'currency', currency: selectedPlan.currency }).format(getFormattedAmount(tier.amount, selectedPlan.currency))

  return <Wrapper>
    <div className='title'>{TITLES[selectedPlan?.period]} plan</div>
    <div className='amount'>
      { discountedAmount ? <>
        <s>{amount}</s>{discountedAmount}
      </> : amount}
    </div>
    <div className='frequency'>{frequency}</div>
    { !tierHasUnitPrice && <div className='up-to'>For {tier.upTo > 1 ? `up to ${tier.upTo} children` : `1 child`} </div> }
    { tierHasUnitPrice && <div className='up-to'>
      { tier.flatAmount > 0 ? `${unitAmount} + ` : `` }
      {unitAmount} per child per month</div> }
    <CouponWrapper>
      { !discount && <div className='label' onClick={() => setEnteringCoupon(d => !d)} role="button" tabIndex={0} onKeyPress={(e) => e.which === 13 && setEnteringCoupon(d => !d)}>Do you have a coupon code?</div> }
      { couponError && <div className='error'>{couponError}</div> }
      { discount && <div className='discount'>{discount * 100}% OFF applied</div>}
      { enteringCoupon && <div>
        <Input ref={couponRef} placeholder='Coupon code' />
        <Button size='x-small' theme='confirm' onClick={onApplyCoupon}>Apply</Button>
      </div> }
    </CouponWrapper>
  </Wrapper>
}

export default SubscriptionDetails
