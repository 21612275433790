export const getFormattedAmount = (amount, currency) => {
  let newAmount = amount
  if (['jpy'].indexOf(currency.toLowerCase()) < 0) {
    newAmount /= 100
  }
  return newAmount
}

export const ENABLED_COUNTRIES = [
  'GB',
  'US',
  'CA',
  'AU',
  'NZ',
  'IL',
  'BR',
  'HK',
  'AR',
  'MX',
  'SG',
  'CL',
  'KR',
  'JP',
  'QA',
  'IN',
  'PK',
  'AE',
  'ID',
  'CN',
  'SE',
  'ZA',
  'MY',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SI',
  'ES'
]


export const getCurrencyFromCountry = (countryCode) => {
  switch (countryCode) {
    case 'GB':
      return 'gbp'
    case 'US':
      return 'usd'
    case 'CA':
      return 'cad'
    case 'AU':
      return 'aud'
    case 'NZ':
      return 'nzd'
    case 'IL':
      return 'ils'
    case 'BR':
      return 'brl'
    case 'HK':
      return 'hkd'
    case 'AR':
      return 'ars'
    case 'MX':
      return 'mxr'
    case 'SG':
      return 'sgd'
    case 'CL':
      return 'clp'
    case 'KR':
      return 'sek'
    case 'JP':
      return 'jpy'
    case 'QA':
      return 'qar'
    case 'IN':
      return 'inr'
    case 'PK':
      return 'pkr'
    case 'AE':
      return 'aed'
    case 'ID':
      return 'idr'
    case 'CN':
      return 'cny'
    case 'SE':
      return 'sek'
    case 'ZA':
      return 'zar'
    case 'MY':
      return 'myr'
    case 'BE':
      return 'eur'
    case 'BG':
      return 'eur'
    case 'HR':
      return 'eur'
    case 'CY':
      return 'eur'
    case 'CZ':
      return 'eur'
    case 'DK':
      return 'eur'
    case 'EE':
      return 'eur'
    case 'FI':
      return 'eur'
    case 'DE':
      return 'eur'
    case 'GR':
      return 'eur'
    case 'HU':
      return 'eur'
    case 'IE':
      return 'eur'
    case 'IT':
      return 'eur'
    case 'LV':
      return 'eur'
    case 'LT':
      return 'eur'
    case 'LU':
      return 'eur'
    case 'MT':
      return 'eur'
    case 'NL':
      return 'eur'
    case 'PL':
      return 'eur'
    case 'PT':
      return 'eur'
    case 'RO':
      return 'eur'
    case 'SI':
      return 'eur'
    case 'ES':
      return 'eur'
    default:
      return 'usd'
  }
}
