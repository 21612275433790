import { Close, Button } from 'nzk-react-components'
import React, { useEffect } from 'react'
import styled from 'styled-components'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
`

const Wrapper = styled.div`
  position: relative;
  background-color: #341644;
  color: #fff;
  border-radius: 19px;
  box-shadow: 0 -3px 0 #531d75, 0 4px 0 #1c042b, 0 8px 0 rgba(0, 0, 0, 0.4);
  > :first-child {
    position: absolute;
    top: -5px;
    right: -5px;
  }
`

export interface IBaseModalProps {
  children?: any,
  dismiss: () => void
}

export default (props: IBaseModalProps) => {
  useEffect(() => {
    if (typeof window === 'undefined') return
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return <Overlay onClick={props.dismiss}>
    <Wrapper onClick={(e) => { e.stopPropagation() }}>
      <Button theme='red' round size='small' onClick={props.dismiss}>
        <Close />
      </Button>
      { props.children }
    </Wrapper>
  </Overlay>
}