import AddChildModal from 'components/AddChildModal';
import BaseModal from 'components/Modals/BaseModal';
import { Avatar, Button, Plus, TrashWhite } from 'nzk-react-components';
import React from 'react';
import styled from 'styled-components';
import { ModalState } from '../../../state/ModalState';
import useSubscribePageState from './useSubscribePageState';

const Children = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 15px;
  margin-top: 3px;
  padding: 10px 0;
`

const ChildCard = styled.div`
  position: relative;
  display: flex;
  background-color: #341644;
  padding: 10px;
  box-shadow: 0 -3px 0 #531d75, 0 4px 0 #1c042b, 0 8px 0 rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  > :first-child { margin-right: 10px; }
`

const DeleteButton = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
`

const AddChildCard = styled(ChildCard)`
  justify-content: center;
  cursor: pointer;
`

const ModalWrapper = styled.div`
  h2 {
    font-size: 22px;
    line-height: 26px;
    text-shadow: 0px 3px #afafaf, 0px 6px rgba(0, 0, 0, 0.3);
    color: #ffffff;
    font-family: 'Rammetto One', cursive;
    text-align: center;
    margin-bottom: 15px;
  }
  padding: 10px 30px 30px 30px;
  a {
    color: inherit;
  }
`

const Username = styled.div`
  font-size: 16px;
`

const Age = styled.div`
  font-size: 14px;
`

const Nickname = styled.div`
`

const YEAR_TO_AGE = [
  { value: 0, label: '4-5' },
  { value: 1, label: '5-6' },
  { value: 2, label: '6-7' },
  { value: 3, label: '7-8' },
  { value: 4, label: '8-9' },
  { value: 5, label: '9-10' },
  { value: 6, label: '10-11' },
  { value: 7, label: '12+' },
]

const ChildrenRow = () => {
  const { userData, childrenToCreate, addChildToCreate, canAddChild, removeChildToCreateAtIndex } = useSubscribePageState()
  const { displayModal, dismissModal } = ModalState.useContainer()

  if (!userData) return null
  if (!((userData?.children || []).length > 0)) return null

  const getAge = (student: any) => {
    const now = new Date()
    const yearNow = now.getFullYear()
    const monthNow = now.getMonth()
    if (student.birthYear && student.birthMonth >= 0) {
      let yearAge = yearNow - student.birthYear
      if (monthNow <= student.birthMonth) {
        yearAge -= 1
      }
      return yearAge
    }
    if (student.age) {
      return student.age
    }
    if (YEAR_TO_AGE[student.yearGroup]) {
      return `${YEAR_TO_AGE[student.yearGroup].label}`
    }
    return 'Not Set'
  }
  
  const onAddChild = () => {
    if (!canAddChild) return
    displayModal(<AddChildModal dismiss={dismissModal} onSubmit={(c) => {
      addChildToCreate(c)
    }} />)
  }

  const showDeleteChildModal = () => {
    displayModal(<BaseModal dismiss={dismissModal}>
      <ModalWrapper>
        <h2>Support required</h2>
        <p>To delete children from your account, please email <a href="mailto:support@nightzookeeper.com">support@nightzookeeper.com</a></p>
      </ModalWrapper>
    </BaseModal>)
  }

  return <Children>
    { userData.children.map(c => <ChildCard key={c._id}>
      <Avatar user={{ ...c, type: 'student' }} />
      <div>
        <Username>{c.username}</Username>  
        <Nickname>{c.nickname}</Nickname>  
        <Age>Age: {getAge(c)} years old</Age>
        <DeleteButton>
          <Button size='x-small' theme='red' round onClick={() => showDeleteChildModal()}>
            <TrashWhite />
          </Button>
        </DeleteButton>
      </div>
    </ChildCard>) }
    {
      childrenToCreate.map((c, i) => <ChildCard key={i}>
        <Avatar user={{ type: 'student' }} />
        <div>
          <Nickname>{c.nickname}</Nickname>  
          <Age>Age: {getAge(c)} years old</Age>
          
        </div>
        <DeleteButton>
          <Button size='x-small' theme='red' round onClick={() => removeChildToCreateAtIndex(i)}>
            <TrashWhite />
          </Button>
        </DeleteButton>
        
      </ChildCard>)
    }
    <AddChildCard onClick={onAddChild}>
      <Button size='regular' round theme='purple' disabled={!canAddChild}>
        <Plus />
      </Button>
    </AddChildCard>
  </Children>
}

export default ChildrenRow
