import styled from 'styled-components'

export const FieldGroup = styled.div`
  display: flex;
  width: 100%;
  > * { flex: 1; margin-right: 10px; }
  > :last-child { margin-right: 0; }
`

export const FormField = styled.div`
  margin-bottom: 10px;
  > * { margin-bottom: 5px; }
  > .label {
    display: flex;
    align-items: center;
    font-size: 14px;
    > * { margin-right: 8px; }
    > :last-child { margin-right: 0; }
  }
`

export const Input = styled.input`
  border-radius: 8px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 2px;
  width: 100%;
  outline: none;
  border: 1.5px solid #8423CA;
  padding: 10px;
`